.products-appear {
    opacity: 0.01;
    transform: scale(0.9) translateX(50%);
}
.products-appear-done {
    opacity: 1;
    transform: scale(1) translateX(0%);
    transition: all 300ms ease-out;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.toast-mobile {
    margin-top: 3.5rem;
}

.main-link, .main-link:visited  {
    text-decoration: none;
    color: inherit;
}
